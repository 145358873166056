<template>
  <div v-if="editor" class="button-container">
    <div>
      <!-- Bold -->
      <v-tooltip top open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            @click="editor.chain().focus().toggleBold().run()"
            :class="{ 'is-active': editor.isActive('bold') }"
            class="editor-button"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon size="24">mdi-format-bold</v-icon>
          </v-btn>
        </template>
        <span>{{ t('Action_.tooltip.bold') }}</span>
      </v-tooltip>
      <!-- Italic -->
      <v-tooltip top open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            @click="editor.chain().focus().toggleItalic().run()"
            :class="{ 'is-active': editor.isActive('italic') }"
            class="editor-button"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon size="24">mdi-format-italic</v-icon>
          </v-btn>
        </template>
        <span>{{ t('Action_.tooltip.italic') }}</span>
      </v-tooltip>
      <!-- Striketrough -->
      <v-tooltip top open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            @click="editor.chain().focus().toggleStrike().run()"
            :class="{ 'is-active': editor.isActive('strike') }"
            class="editor-button"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon size="18">mdi-format-strikethrough-variant</v-icon>
          </v-btn>
        </template>
        <span>{{ t('Action_.tooltip.striketrough') }}</span>
      </v-tooltip>
      <!-- Highlight -->
      <v-tooltip top open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            @click="editor.commands.toggleHighlight()"
            :class="{ 'is-active': editor.isActive('highlight') }"
            class="editor-button"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon size="18">mdi-marker</v-icon>
          </v-btn>
        </template>
        <span>{{ t('Action_.tooltip.highlight') }}</span>
      </v-tooltip>
    </div>

    <div class="divider" />

    <div>
      <!-- Title -->
      <v-tooltip top open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
            class="editor-button"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon size="22">mdi-format-header-1</v-icon>
          </v-btn>
        </template>
        <span>{{ t('Action_.tooltip.title') }}</span>
      </v-tooltip>
      <!-- Subtitle -->
      <v-tooltip top open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
            class="editor-button"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon size="22">mdi-format-header-2</v-icon>
          </v-btn>
        </template>
        <span>{{ t('Action_.tooltip.subtitle') }}</span>
      </v-tooltip>
    </div>

    <div class="divider" />

    <div>
      <!-- Bullet list -->
      <v-tooltip top open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            @click="editor.chain().focus().toggleBulletList().run()"
            :class="{ 'is-active': editor.isActive('bulletList') }"
            class="editor-button"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>
        </template>
        <span>{{ t('Action_.tooltip.bulletList') }}</span>
      </v-tooltip>
      <!-- Numbered list -->
      <v-tooltip top open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            @click="editor.chain().focus().toggleOrderedList().run()"
            :class="{ 'is-active': editor.isActive('orderedList') }"
            class="editor-button"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-format-list-numbered</v-icon>
          </v-btn>
        </template>
        <span>{{ t('Action_.tooltip.numberedList') }}</span>
      </v-tooltip>
      <!-- Task list -->
      <v-tooltip top open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            @click="editor.chain().focus().toggleTaskList().run()"
            :class="{ 'is-active': editor.isActive('taskList') }"
            class="editor-button"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-format-list-checkbox</v-icon>
          </v-btn>
        </template>
        <span>{{ t('Action_.tooltip.taskList') }}</span>
      </v-tooltip>
    </div>

    <div class="divider" />

    <div>
      <!-- Blockquote -->
      <v-tooltip top open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            @click="editor.chain().focus().toggleBlockquote().run()"
            :class="{ 'is-active': editor.isActive('blockquote') }"
            class="editor-button"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-format-quote-close</v-icon>
          </v-btn>
        </template>
        <span>{{ t('Action_.tooltip.blockquote') }}</span>
      </v-tooltip>
      <!-- Horizontal divider -->
      <v-tooltip top open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            @click="editor.chain().focus().setHorizontalRule().run()"
            class="editor-button"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </template>
        <span>{{ t('Action_.tooltip.horizontalDivider') }}</span>
      </v-tooltip>
    </div>

    <div class="divider" />

    <div>
      <!-- Clear format -->
      <v-tooltip top open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            @click="editor.commands.clearNodes()"
            class="editor-button"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-format-clear</v-icon>
          </v-btn>
        </template>
        <span>{{ t('Action_.tooltip.clearFormat') }}</span>
      </v-tooltip>
    </div>

    <div class="divider" />

    <div>
      <!-- Undo -->
      <v-tooltip top open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            @click="editor.chain().focus().undo().run()"
            class="editor-button"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon size="22">mdi-undo</v-icon>
          </v-btn>
        </template>
        <span>{{ t('Action_.tooltip.undo') }}</span>
      </v-tooltip>
      <!-- Redo -->
      <v-tooltip top open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            @click="editor.chain().focus().redo().run()"
            class="editor-button"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon size="22">mdi-redo</v-icon>
          </v-btn>
        </template>
        <span>{{ t('Action_.tooltip.redo') }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script setup>
import languages from '../../locales/languages';
import { defineProps } from 'vue';

import { useI18n } from 'vue-i18n-bridge';

const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  editor: {
    type: Object,
    required: true,
  },
});
</script>

<style scoped lang="scss">
.button-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  background-color: #f2f2f2;
  padding: 6px;
  border-radius: 8px 8px 0px 0px;
  border-top: 1px solid $ash-dark;
  border-right: 1px solid $ash-dark;
  border-left: 1px solid $ash-dark;
}

.editor-button {
  box-shadow: none;
  height: 28px !important;
  width: 26px !important;
  min-width: 26px !important;
  margin: 6px 4px;
  background-color: #f2f2f2 !important;
}

.divider {
  width: 2px;
  height: 28px;
  background-color: #0000001a;
  margin: 6px 4px;
}

.is-active {
  background-color: #dedede !important;
}
</style>
