<template>
  <div class="mineBlack--text" data-testid="rich-text-editor">
    <TiptapSetting v-if="editor" :editor="editor" />
    <Editor-Content class="text-area" :editor="editor" />
  </div>
</template>

<script>
import TiptapSetting from './TiptapSetting.vue';
import { Editor, EditorContent } from '@tiptap/vue-2';
import Placeholder from '@tiptap/extension-placeholder';
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';

export default {
  name: 'Tiptap',
  components: {
    TiptapSetting,
    EditorContent,
  },

  data() {
    return {
      editor: null,
    };
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
        Highlight,
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
      ],
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML());
      },
      content: this.value,
    });
  },

  watch: {
    value(value) {
      const contentChanged = this.editor.getHTML() === value;
      if (contentChanged) return;
      this.editor.commands.setContent(value, false);
    },
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
.text-area {
  background-color: white;
  padding: 1.25rem 1rem;
  min-height: 150px;
  border-radius: 0px 0px 8px 8px;
  border-right: 1px solid $ash-dark;
  border-left: 1px solid $ash-dark;
  border-bottom: 1px solid $ash-dark;
}

/* Basic editor styles */
.ProseMirror {
  &:focus {
    outline: none;
  }

  > * + * {
    margin-top: 0.75em;
  }
  ul,
  ol {
    padding: 0 1rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }
  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }
  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

ul[data-type='taskList'] {
  list-style: none;
  padding: 0;

  p {
    margin: 0;
  }

  li {
    display: flex;

    > label {
      flex: 0 0 auto;
      margin-top: 1.5px;
      margin-right: 0.5rem;
      user-select: none;
    }

    > div {
      flex: 1 1 auto;
    }
  }
}
</style>
